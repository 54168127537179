body,
html {
  height: 100%;
}

.ps--full-container,
.ps--full-image-container {
  position: relative;
  overflow-x: hidden;
}

.ps--full-container {
  background: $background;
}

// imagen fondo login
.ps--full-image-container {
  background-size: cover;
  .ps--header-disconnect {
    color: white;
  }
}

// imagen fondo mapfre
.ps--full-image-container-mapfre {
  //background: url("../../assets/images/ps-login-background.png") no-repeat 0 0;
  background-size: cover;
  .ps--header-disconnect {
    // color: white;
    color: #ec0000;
  }
}

.ps--full-image-container-correos {
  //background: url("../../assets/images/correosb&w.png") no-repeat 0 0;
  background-size: cover;
  background-position: bottom;
  .ps--header-disconnect {
    color: white;
  }
}

.ps--full-image-container-comerciales {
  //background: url("../../assets/images/fondoComerciales_edited.jpg") no-repeat 0 0;
  background-size: cover;
  .ps--header-disconnect {
    color: white;
  }
}
.ps--full-image-container-bancaprivada {
  //background: url("../../assets/images/fondoComerciales_edited.jpg") no-repeat 0 0;
  background-size: cover;
  .ps--header-disconnect {
    // color: white;
    color: #ec0000;
  }
}
.ps--full-image-container-rentingauto {
  background: url("../../assets/images/fondoRentingAuto.jpg") no-repeat 0 0;
  background-size: cover;
  .ps--header-disconnect {
    color: white;
  }
}

.ps--full-image-container-universidades {
  background: url("../../assets/images/fondoUniversidades.jpg") round 0 0;
  background-size: cover;
  .ps--header-disconnect {
    // color: white;
    color: #ec0000;
  }
}
.ps--full-image-container-digital {
  background: url("../../assets/images/fondoDigital.jpg") round 0 0;
  background-size: cover;
  .ps--header-disconnect {
    color: white;
  }
}

@media (min-height: 540px) {
  .ps--full-image-container,
  .ps--full-image-container-mapfre,
  .ps--full-image-container-correos,
  .ps--full-image-container-comerciales,
  .ps--full-image-container-bancaprivada,
  .ps--full-image-container-rentingauto,
  .ps--full-image-container-universidades,
  .ps--full-image-container-digital {
    height: 100%;
  }
}

.ps--header-disconnect {
  @media (min-width: 576px) {
    margin-right: 1rem;
  }
}

.no-gutters {
  @media (max-width: 960px) {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  @media (min-width: 960px) {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.ps--form-container,
.ps--seguimiento-box-container,
.ps--factura-box-container,
.ps--factura-box-container-table {
  background: white;
  border: 1px solid $item-inner;
  padding: 1.25rem 2.5rem;
  @media (max-width: 960px) {
    padding: 1.25rem;
  }
}

.ps--seguimiento-activable {
  padding: 1.25rem 2.5rem 1.35rem 2.5rem;
  @media (max-width: 960px) {
    padding: 1.25rem 1.25rem 1.35rem 1.25rem;
  }
}

.ps--form-container,
.ps--seguimiento-box-container,
.ps--factura-box-container,
.ps--noticias-box-container,
.ps--detalle-noticias-box,
.ps--factura-box-container-table,
.ps--noticias-box-container-table {
  @media (max-width: 960px) {
    margin-right: 1rem;
    margin-left: 1rem;
    .no-gutters {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.fixed-width {
  @media (min-width: 960px) {
    max-width: 90%;
    margin: 0 auto;
  }
  @media (min-width: 1440px) {
    max-width: 70%;
    margin: 0 auto;
  }
}

.fixed-width-ppcc {
  max-width: 90%;
  margin: 0 auto;
}
